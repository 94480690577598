<script>
import Swal from "sweetalert2";
import Layout from "@/views/layouts/main.vue";

export default {

  components: {
    Layout
  },

  methods: {
    async fetchPaymentStatus() {
      const status = this.$route.params.status;
      if (!status) {
        return;
      }

      const isSuccess = status === 'success'

      await Swal.fire(isSuccess ? "Sukces!" : "Błąd!", isSuccess ? "Proces realizacji transakcji przebiegł pomyślnie!" : "Proces realizacji transakcji nie powiódł się!", isSuccess ? "success" : "error")
          .then(() => {
            this.$router.push('/dashboard/home').catch((error) => {
              if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
                console.log(error)
              }
            })
          });
    }
  },

  async created() {
    await this.fetchPaymentStatus()
  }

};
</script>

<template>
  <Layout>

  </Layout>
</template>